import Cookies from "universal-cookie";
const cookies = new Cookies();

export const getLocalUserDetails = async () => {
    var user = await cookies.get('vitel_company')
    return user ? user : null
};

export const getToken = async () => {
    var user = await cookies.get('vitel_company')
    return user ? user.token : null
};

export const setToken = async (user) => {
    await cookies.set('vitel_company', JSON.stringify(user), { path: '/', maxAge: 300 * 60 });
};

export const removeToken = async () => {
    await cookies.remove('vitel_company');
};

