import axios from "axios";
import { BASE_URL } from "../../config";
import { getToken } from './token.service'

const axiosInstance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})



axiosInstance.interceptors.request.use(
  async config => {
    const token = await getToken()
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default axiosInstance;

// export const axiosInstanceUser = axios.create({
//   baseURL: `${URL}`,
//   headers: {
//     'Content-Type': 'application/json; charset=utf-8',
//     'Accept': 'application/json',
//     'Access-Control-Allow-Origin': '*'
//   }
// })