import ReactLoading from "react-loading";
import { Box } from "@mui/material";

function Loading() {
  var width = 220;
  return (
    <Box
      sx={{
        left: {
          lg: width,
        },
        width: {
          lg: `calc(100% - ${width}px)`,
        },
      }}
      className="loading"
    >
      <ReactLoading type="spinningBubbles" color="#000000" />
    </Box>
  );
}

export default Loading;
