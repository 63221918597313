import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "../actionTypes";

const initialState = {
  isLoading: false,
  user: null
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        user: payload.data.user,
        isLoading: false
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        user: null,
        isLoading: false
      };
    }
    case LOGOUT_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        user: null,
        isLoading: false
      };
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        user: null,
        isLoading: false
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default userReducer;
