import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { AppBar, Box, IconButton, Toolbar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
// import UserCircleIcon from '@mui/icons-material/Person';
// import { MyConfirmation } from "../components"
// import { useDispatch } from "react-redux";
// import { logoutUser } from "../store/actions/auth.actions";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
    const { onSidebarOpen, ...other } = props;
    // const [logoutModal, setLogoutModal] = useState(false);
    // const dispatch = useDispatch();

    // const handleLogout = () => {
    //     dispatch(logoutUser());
    // }

    // const handleLogoutModal = (value) => {
    //     setLogoutModal(value)
    // }

    return (
        <>
            <DashboardNavbarRoot
                sx={{
                    left: {
                        lg: 280
                    },
                    width: {
                        lg: 'calc(100% - 280px)'
                    }
                }}
                {...other}>
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 64,
                        left: 0,
                        px: 2
                    }}
                >
                    <IconButton
                        onClick={onSidebarOpen}
                        sx={{
                            display: {
                                xs: 'inline-flex',
                                lg: 'none'
                            }
                        }}
                    >
                        <MenuIcon fontSize="small" />
                    </IconButton>
                    {/* <Tooltip title="Search">
                        <IconButton sx={{ ml: 1 }}>
                            <SearchIcon fontSize="small" />
                        </IconButton>
                    </Tooltip> */}
                    <Box sx={{ flexGrow: 1 }} />

                    {/* <Tooltip title="Logout">
                        <IconButton
                            onClick={() => { handleLogoutModal(true) }}
                            sx={{ ml: 1 }}>
                            <UserCircleIcon
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip> */}
                </Toolbar>
            </DashboardNavbarRoot>

            {/* <MyConfirmation
                description="Are you sure you want to logout ?"
                open={logoutModal}
                onClose={() => { handleLogoutModal(false) }}
                handleSubmit={handleLogout}
            /> */}
        </>
    );
};

DashboardNavbar.propTypes = {
    onSidebarOpen: PropTypes.func
};