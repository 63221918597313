import { loginService, logoutService } from "../services/auth.service";
import { setToken, removeToken } from "../services/token.service";
// import { history } from "../../history"
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE
} from "../actionTypes";

export function loginUser(data) {
  return async dispatch => {
    dispatch({
      type: LOGIN_REQUEST
    })
    try {
      const response = await loginService(data)
      await setToken(response.data.user)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response
      })
      window.location.href = "/company"
    } catch (error) {
      dispatch({
        type: LOGIN_FAILURE,
        payload: error
      })
    }
  }
}

export function logoutUser() {
  return async dispatch => {
    dispatch({
      type: LOGOUT_REQUEST
    })
    try {
      const response = await logoutService()
      await removeToken()
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: response
      })
      window.location.href = "/"
    } catch (error) {
      dispatch({
        type: LOGOUT_FAILURE,
        payload: error
      })
    }
  }
}
