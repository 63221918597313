import React from "react";

function MyPage({ children }) {
  return (
    <div>
      {children}
    </div>
  );
}

export default MyPage;
