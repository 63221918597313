import { combineReducers } from "redux";
import authReducer from "./authReducer";
import companyReducer from "./companyReducer";
import reportReducer from "./report.Reducer"

const reducers = combineReducers({
  auth: authReducer,
  company: companyReducer,
  report:reportReducer,
  
});

export default reducers;