import React from 'react'
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyConfirmation = ({
  open,
  description,
  onClose,
  handleSubmit
}) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        borderRadius: 0,
      }}
    >
      <DialogTitle
        sx={{
          padding: "10px 24px"
        }}
      >
        <Tooltip arrow title="Close">
          <IconButton
            color="primary"
            sx={{
              cursor: 'pointer',
              float: 'right'
            }}
            size="small"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent
        sx={{
          backgroundColor: '#fcfcfc',
          padding: '1rem 1.5rem'
        }}
        dividers>

        <Typography
          color="primary"
          sx={{
            textAlign: "center",
            lineHeight: '2.5rem',
            fontWeight: 600
          }}
          variant="h5" >
          {description}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          size="medium"
          variant="contained"
        >
          No
        </Button>

        <Button
          color="secondary"
          onClick={handleSubmit}
          size="medium"
          variant="contained"
        >
          Yes
        </Button>

      </DialogActions>
    </Dialog>
  )
}

export default MyConfirmation;