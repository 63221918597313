import { Box, Drawer, useMediaQuery } from '@mui/material';
import { NavItem } from './nav-item';
import BusinessIcon from '@mui/icons-material/Business';
import ReportIcon from '@mui/icons-material/Report';
import { useState } from 'react';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { MyConfirmation } from "../components"
import { useDispatch } from "react-redux";
import { logoutUser } from "../store/actions/auth.actions";

const items = [
    {
        href: '/company',
        icon: (<BusinessIcon  fontSize="small" />),
        title: 'Company'
    },
    {
        href: '/report',
        icon: (<ReportIcon fontSize="small" />),
        title: 'Report Message'
    },
    {
        href: '/reportUser',
        icon: (<ReportIcon fontSize="small" />),
        title: 'Report User'
    }
];

export const DashboardSidebar = (props) => {
    const [logoutModal, setLogoutModal] = useState(false);
    const { open, onClose } = props;
    const dispatch = useDispatch();

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });
    const handleLogoutModal = (value) => {
        setLogoutModal(value)
    }
    const handleLogout = () => {
        dispatch(logoutUser());
    }

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding:'40px 0px'
                }}
            >
                <Box sx={{ flexGrow: 1}}>
                    {items.map((item) => (
                        <NavItem
                            key={item.title}
                            icon={item.icon}
                            href={item.href}
                            title={item.title}
                        />
                    ))}
                </Box>

                <Button 
                        variant="none"
                        onClick={() => { handleLogoutModal(true) }}
                        sx={{ mb:5,mr:16,color:"#ad3d37"}}
                        startIcon ={<LogoutIcon />}>  Logout   
                </Button>

                <MyConfirmation
                description="Are you sure you want to logout ?"
                open={logoutModal}
                onClose={() => { handleLogoutModal(false) }}
                handleSubmit={handleLogout}
            />
            </Box>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        color: '#FFFFFF',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
        
    );
};