import React, { useEffect, useState, lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MyLayout from "../layout";
import { MyLoader } from "../components";
import { getToken } from "../store/services/token.service";


const LoginScreen = lazy(() => import("../pages/Login"));
const CompanyScreen = lazy(() => import("../pages/Company"));
const CompanyUsersScreen = lazy(() => import("../pages/CompanyUsers"));
const ReportUsers = lazy(() => import("../pages/ReportUsers"));
const Report = lazy(() => import("../pages/ReportData"));
const NotFoundScreen = lazy(() => import("../pages/NotFound"));


const AppNavigator = () => {
  const [isAuthorised, setAuthorised] = useState(true);
  useEffect(() => {
    async function checkUserLoggedIn() {
      var token = await getToken()
      if (!token) {
        setAuthorised(false)
      }
    }
    checkUserLoggedIn()
  }, [])


  return (
    <Suspense fallback={<MyLoader />}>
      <Routes>
        <Route
          exact
          path="/"
          element={<LoginScreen />}
        />

        <Route
          exact
          path="/company"
          element={
            isAuthorised ?
              <MyLayout>
                <CompanyScreen />
              </MyLayout> :
              <Navigate
                to="/"
                replace
              />
          }
        />

        <Route
          path="/users"
          element={
            isAuthorised ?
              <MyLayout>
                <CompanyUsersScreen />
              </MyLayout> :
              <Navigate
                to="/"
                replace
              />
          }
        />

        <Route
          path="/report"
          element={
            isAuthorised ?
              <MyLayout>
                <ReportUsers />
              </MyLayout> :
              <Navigate
                to="/"
                replace
              />
          }
        />

        
          <Route
          path="/reportUser"
          element={
            isAuthorised ?
              <MyLayout>
                <Report />
              </MyLayout> :
              <Navigate
                to="/"
                replace
              />
          }
        />

        <Route
          path="*"
          element={
            <MyLayout>
              <NotFoundScreen />
            </MyLayout>
          }
        />
      </Routes>
    </Suspense>
  );
};
export default AppNavigator;
