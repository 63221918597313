import {
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,

  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,

  EDIT_COMPANY_REQUEST,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,

  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,

  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,

  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,

  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  
} from "../actionTypes";

const initialState = {
  isLoading: false,
  companyData: [],
  companyData1:0,
  usersData : []
};

const companyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case FETCH_COMPANY_SUCCESS: {
      return {
        ...state,
        companyData: payload.data.companyData,
        isLoading: false
      };
    }

    case FETCH_COMPANY_FAILURE: {
      return {
        ...state,
        companyData: [],
        isLoading: false
      };
    }

    case CREATE_COMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case CREATE_COMPANY_SUCCESS: {
      return {
        ...state,
        companyData: [...state.companyData, payload.data.post],
        companyData1: payload.data.success,
        isLoading: false
      };
    }

    case CREATE_COMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

    case EDIT_COMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case EDIT_COMPANY_SUCCESS: {
      let newData = payload.data.post
      let newCompanyData = state.companyData.map((item) => item._id === newData._id ? { ...newData } : item)
      return {
        ...state,
        isLoading: false,
        companyData: newCompanyData
      };
    }

    case EDIT_COMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

    case DELETE_COMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case DELETE_COMPANY_SUCCESS: {
      return {
        ...state,
        companyData: state.companyData.filter(items => items._id !== payload),
        isLoading: false
      };
    }

    case DELETE_COMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

    case FETCH_USER_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        usersData: payload.data.usersData,
        isLoading: false
      };
    }

    case FETCH_USER_FAILURE: {
      return {
        ...state,
        usersData: [],
        isLoading: false
      };
    }

    case CREATE_USER_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        usersData: [...state.usersData, payload.data.post],
        isLoading: false
      };
    }

    case CREATE_USER_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

    case EDIT_USER_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case EDIT_USER_SUCCESS: {
      let newData = payload.data.post
      let newusersData = state.usersData.map((item) => item._id === newData._id ? { ...newData } : item)
      return {
        ...state,
        isLoading: false,
        usersData: newusersData
      };
    }

    case EDIT_USER_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

    case DELETE_USER_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        usersData: state.usersData.filter(items => items._id !== payload),
        isLoading: false
      };
    }

    case DELETE_USER_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default companyReducer;