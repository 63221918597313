import React, { useState } from "react";
import {
  Tooltip,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const MoreMenu = (props) => {

  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleOpen = (event) => {
    props.moreMenuClick();
    setSortAnchorEl(event.currentTarget)
  };

  const handleClose = (value) => {
    if (value) {
      value.onClick(value.sortName);
    }
    setSortAnchorEl(null)
  };

  return (
    <>
      <Tooltip arrow title="More">
        <IconButton
          aria-label="More"
          aria-haspopup="true"
          onClick={handleOpen}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Menu
        sx={{
          minWidth: "250px",
          borderRadius: "5px",
        }}
        id="customized-menu"
        MenuListProps={{ disablePadding: true }}
        anchorEl={sortAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        open={Boolean(sortAnchorEl)}
        onClose={(e) => {
          handleClose("");
        }}
      >
        {props.moreData.map((value, index) => {
          return (
            <MenuItem
              key={index}
              disabled={
                value.permission === undefined
                  ? false
                  : value.permission
                    ? false
                    : true
              }
              onClick={(e) => {
                handleClose(value);
              }}
            //className={classes.MenuItem}
            >
              <ListItemIcon
                sx={{
                  minWidth: "45px",
                }}
              >
                <value.icon
                  sx={{
                    color: "#7C7C7C",
                    height: 24,
                    width: 24,
                  }}
                />
              </ListItemIcon>

              <Typography
                color="textPrimary"
                variant="inherit"
                sx={{
                  color: "#202223",
                  fontSize: "15px",
                  lineHeight: "2rem",
                }}
              >
                {value.title}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>

    </>
  )
}

export default MoreMenu