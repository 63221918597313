import { Link } from 'react-router-dom';
import { Box, Button, ListItem } from '@mui/material';

export const NavItem = (props) => {
    const { href, icon, title, ...others } = props;
    //const router = useRouter();
    //const active = href ? (router.pathname === href) : false;
    const active = false
    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                mb: 0.5,
                py: 0,
                px: 2
            }}
            {...others}
        >
            <Link
                style={{
                    width: "100%"
                }}
                to={href}
            >
                <Button
                    component="a"
                    startIcon={icon}
                    disableRipple
                    sx={{
                        backgroundColor: "/" + title.toLowerCase() === window.location.pathname ?'rgba(255,255,255, 0.08)': '#111827',
                        borderRadius: 1,
                        color: active ? 'secondary.main' : 'neutral.300',
                        fontWeight: active && 'fontWeightBold',
                        justifyContent: 'flex-start',
                        px: 3,
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                        '& .MuiButton-startIcon': {
                            color: active ? 'secondary.main' : 'neutral.400'
                        },
                        '&:hover': {
                            // backgroundColor: 'rgba(255,255,255, 0.08)'
                        }
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        {title}
                    </Box>
                </Button>
            </Link>
        </ListItem>
    );
};
