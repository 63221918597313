import React from 'react'
import {
  Dialog,
  DialogContent,
  Slide,
  DialogTitle,
  Typography,
  Tooltip,
  Button,
  IconButton,
  DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MyLeftModalWithButtons = ({
  children,
  open,
  title,
  onClose,
  isDisabled,
  handleSubmit
}) => {
  return (
    <Dialog
      maxWidth="sm"
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        margin: "0px 0px 0px auto",
        right: "0px",
        left: "auto",
        width: {
          xs: "100%",
          sm: "100%",
          md: "25%"
        },
        borderRadius: 0,
      }}
    >
      <DialogTitle
        sx={{
          padding: "10px 24px"
        }}
      >
        <Typography
          color="primary"
          sx={{
            lineHeight: '2.5rem',
            float: "left",
            fontWeight: 600
          }}
          variant="h6" >
          {title}
        </Typography>

        <Tooltip arrow title="Close">
          <IconButton
            color="primary"
            sx={{
              cursor: 'pointer',
              float: 'right'
            }}
            size="small"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent
        sx={{
          backgroundColor: '#fcfcfc',
          padding: '1rem'
        }}
        dividers>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          size="medium"
          variant="contained"
          disabled={isDisabled}
        >
          Close
        </Button>

        <Button
          color="secondary"
          onClick={handleSubmit}
          size="medium"
          variant="contained"
          disabled={isDisabled}
        >
          Save
        </Button>

      </DialogActions>
    </Dialog>
  )
}

export default MyLeftModalWithButtons;