export const apisUrls = {
  login: "/user/login/admin",
  logout: "/user/logout",
  company: "/company",
  user: "/user",
  report:"report/message",
  ignore:"report/message",
  reportUser:"report/user"
};


