import axiosInstance from "./apis";
import { apisUrls } from "./apisUrls";

export const loginService = async (data) => {
  return await axiosInstance
    .post(apisUrls.login, {
      user_email: data.user_email,
      user_password: data.user_password
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const logoutService = async () => {
  return await axiosInstance
    .post(apisUrls.logout)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};