import {
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_REQUEST_USER,
  FETCH_REPORT_SUCCESS_USER,
  FETCH_REPORT_FAILURE_USER,
  DELETE_REPORT_REQUEST,
  DELETE_REPORT_SUCCESS,
  DELETE_REPORT_FAILURE,
  IGNORE_REPORT_REQUEST,
  IGNORE_REPORT_SUCCESS,
  IGNORE_REPORT_FAILURE,
  SET_ROWS,
  SET_PAGE
} from "../actionTypes";

const initialState = {
  isLoading: false,
  reportMessageData: [],
  reportUserData: [],
  reportUserData1: [],
  reportMessageData1:[],
  pageNo: 0,
  rowsPerPage: 50,
};


const reportReducer = (state = initialState, { type, payload }) => {
  console.log("payload",payload)
  switch (type) {
    case FETCH_REPORT_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case FETCH_REPORT_SUCCESS: {
      
    // const datatemp = state.reportMessageData.concat(payload.data.reportMessageData)
   
      return {
        ...state,
        
        reportMessageData: payload.data.reportMessageData,
        reportMessageData1: payload.data,
        isLoading: false
      };
    }

    case FETCH_REPORT_FAILURE: {
      return {
        ...state,
        reportMessageData: [],
        isLoading: false
      };
    }

    case FETCH_REPORT_REQUEST_USER: {
      return {
        ...state,
        isLoading: true
      };
    }

    case SET_ROWS:
      return {
        ...state,
        rowsPerPage: payload
      };
    case SET_PAGE:
      return {
        ...state,
        pageNo: payload
      };

    case FETCH_REPORT_SUCCESS_USER: {
      return {
        ...state,
        reportUserData: payload.data.reportData,
        reportUserData1: payload.data,
        isLoading: false
      };
    }

    case FETCH_REPORT_FAILURE_USER: {
      return {
        ...state,
        reportUserData: [],
        isLoading: false
      };
    }
   case DELETE_REPORT_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case DELETE_REPORT_SUCCESS: {
      return {
        ...state,
        reportMessageData: state.reportMessageData.filter(items => items._id !== payload),
        isLoading: false
      };
    }

    case DELETE_REPORT_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

   case IGNORE_REPORT_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case IGNORE_REPORT_SUCCESS: {
      return {
        ...state,
        reportMessageData: state.reportMessageData.filter(items => items._id !== payload),
        isLoading: false
      };
    }

    case IGNORE_REPORT_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

   
    default:
      return {
        ...state,
      };
  }
};

export default reportReducer;